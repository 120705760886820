import { ErrorMessage } from 'features/admin/clientData/components/analisysModal/styles';
import UploadIcon from 'images/upload.svg';
import { Attachment } from 'model/attachment';
import { UploadedFileType } from 'model/uploaded-file';
import { useRegisterForm } from 'provider/register-form';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Container, ContainerDataDocument, ContainerTitleAndData, DragAndDrop, FileContainer, Label, Upload } from './styles';
import { AttachmentType } from 'model/enums/attachment-types';
import Delete from '../../images/close-black.svg';
import { truncate } from 'lodash';

interface Props {
  title?: string;
  nameInput?: string;
  name?: string;
  type?: string;
  existentFile?: Attachment;
}
export default function FileUpload({ nameInput, name, type, existentFile }: Props) {
  const { t } = useTranslation();
  const [invalidDocumentFormat, setInvalidDocumentFormat] = useState<string>();
  const [file, setFile] = useState<Attachment>();

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const handleRegister = register(name ?? '');

  const SliceFilename = (file: string) => {
    const splitFile = file.split('.');
    const len = splitFile.length;
    const extension = splitFile[len - 1];
    return `${splitFile[0].split('').slice(0, 6).join('')}...${extension}`;
  };

  useEffect(() => {
    if (existentFile != null) {
      setFile(existentFile);
      setValue(name ?? '', existentFile);
    }
  }, [existentFile]);

  const onHandleChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files?.[0]?.name) {
      return;
    }
    if (e.target.files) {
      let newFile = e.target.files[0].name;
      if (newFile.split('.')[0].length > 45) {
        newFile = SliceFilename(newFile);
      }
    }
  };

  const handleAddFile = (event: any) => {
    const uploadedFile = event[0];
    const uploadedFileTypes = [
      UploadedFileType.PNG,
      UploadedFileType.DNG,
      UploadedFileType.HEIF,
      UploadedFileType.HEIC,
      UploadedFileType.HEICs,
      UploadedFileType.JPG,
      UploadedFileType.JPEG,
      UploadedFileType.PDF,
    ];
    if (uploadedFile == null) {
      return;
    }

    if (!uploadedFileTypes.includes(uploadedFile.type)) {
      setInvalidDocumentFormat(uploadedFile.type);
      return;
    } else {
      setInvalidDocumentFormat(undefined);
    }

    const reader = new FileReader();
    onHandleChangeField(event);

    reader.onload = (ev: any) => {
      const renameFile = `${Math.floor(Math.random() * 101)}${uploadedFile.name}`;

      const fileData: Attachment = {
        fileName: renameFile,
        contentType: uploadedFile.type,
        file: ev.target.result.split(',')[1],
        type: type ?? '',
      };

      setFile(fileData);

      setValue(name ?? '', fileData);
    };
    reader.readAsDataURL(uploadedFile);
  };

  const handleRemoveFile = () => {
    setFile(undefined);
    setValue(name ?? '', undefined);
  };

  const renderFile = () => {
    if (file != null) {
      return (
        <FileContainer>
          <ContainerDataDocument>
            <a download={file.fileName} href={file.url} target="_blank">
              {file.fileName && truncate(file.fileName, { length: 25 })}
            </a>
            {file != null && <img src={Delete} onClick={() => handleRemoveFile()} />}
          </ContainerDataDocument>
        </FileContainer>
      );
    }
  };

  const requiredError = name != null && errors[name]?.message != null;

  return (
    <>
      <Dropzone onDrop={acceptedFiles => handleAddFile(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <>
            <Container {...getRootProps()} $required={requiredError ?? false}>
              <DragAndDrop>
                <Upload>
                  <img src={UploadIcon} />
                </Upload>
                <ContainerTitleAndData>
                  <span className="title">{t('documents.container.title')}</span>
                  <span className="subtitle">{`${t('documents.container.formats')} ${t('documents.container.maxSize')}`}</span>
                </ContainerTitleAndData>
              </DragAndDrop>

              <div style={{ marginRight: '16px' }}>
                <Label htmlFor={nameInput}>{t('global.button.searchAttach')}</Label>
                <input
                  type="file"
                  id={nameInput}
                  style={{ display: 'none' }}
                  {...register(name ?? '')}
                  accept="image/x-png, image/jpg, image/jpeg, .pdf"
                  onChange={e => {
                    handleAddFile(e);
                    handleRegister.onChange(e);
                  }}
                  {...getInputProps()}
                />
              </div>
            </Container>
            {requiredError && <ErrorMessage>{t('global.errorMessage.required')}</ErrorMessage>}
            {invalidDocumentFormat && (
              <ErrorMessage>{`${invalidDocumentFormat}: ${t('global.errorMessage.invalidDocumentFormat')}`}</ErrorMessage>
            )}
          </>
        )}
      </Dropzone>
      {renderFile()}
    </>
  );
}
