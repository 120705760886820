import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '@mui/material';
import CustomButton from 'components/custom-button';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import Header from 'components/general-components/header';
import { SimpleSelectInput } from 'components/simple-select-input';
import InputText from 'components/styled-input';
import BankAccountForm from 'features/simulator-result/dashboard/bank-account-form';
import { isEmpty } from 'lodash';
import { AdminAnalyze } from 'model/admin-analyze';
import { BankAccount } from 'model/bank-account';
import { Client } from 'model/client';
import { AdminAnalyzeStatus } from 'model/enums/admin-analyze-status';
import { Authority } from 'model/enums/authority';
import { ProcedureBankAccountType } from 'model/enums/bank-account-type';
import { ContractStatus } from 'model/enums/contract-status';
import FinancingStatusType from 'model/enums/financing-status-type';
import FinancingPaymentType from 'model/enums/financingPaymentType';
import { HeaderVariants } from 'model/enums/header-variants';
import { RegistrationStatus } from 'model/enums/registration-status';
import { RoutePath } from 'model/enums/route-path';
import StepType from 'model/enums/step-type';
import SystemStepCategory from 'model/enums/system-step-category';
import TypesBanks from 'model/enums/types-banks';
import { BankAccountInFinancing, Contract, Financing, Payment, PaymentData, ReimbursementData } from 'model/financing';
import { PaymentMethod } from 'model/procedurePayment';
import { useAdmin } from 'provider/admin-general';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FiChevronLeft } from 'react-icons/fi';
import { ReactComponent as ErrorIcon } from 'images/x-octagon.svg';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IRootState } from 'reducer';
import clientService from 'services/client-service';
import financingService from 'services/financing-service';
import organizationsSystemStepsService from 'services/organization-system-steps-service';
import { hasAuthorities, stepsOrdererByStepMomentAndOrderStep } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import * as yup from 'yup';
import { TabContracts } from '../customerAnalysis/components/tabContracts';
import { TabClientName, TabHeaderContainer, TabHeaderInnerContainer, TabTitleHeader } from '../customerAnalysis/styles';
import { Status } from '../dashboard/components/status';
import { StepBarFinanceProgress } from '../stepBarFinanceProgress';
import { TabDetails } from './components/details';
import ReimbursementPayment from './components/reimbursement-payment';
import ServicePayment from './components/service-payment';
import {
  ButtonsContainer,
  Container,
  FormContainer,
  InputContent,
  KycErrorContainer,
  PageContainer,
  StatusContainer,
  Tab,
  TabBox,
  TabContent,
  Title,
  TitleHeader,
} from './styles';
import { PixType } from 'model/enums/payment-type';
import BankDetailsRegistrationFormPix from 'features/simulator-result/dashboard/bank-details-registration-form-pix';
import { FormalizationType } from '../../../model/enums/formalization-type';
import iuguService from 'services/iugu-service';
import { FinancingStatus } from 'model/enums/financing-status';

interface Schema {
  action: string;
  comment?: string;
  bank?: string;
  payment?: string;
}

export const pixOptions = [
  { label: 'CPF', value: PixType.CPF },
  { label: 'CNPJ', value: PixType.CNPJ },
  { label: 'E-mail', value: PixType.EMAIL },
  { label: 'Número de telefone', value: PixType.PHONE },
  { label: 'Chave aleatória', value: PixType.RANDOM_KEY },
];

export const paymentOptions = [
  { label: 'PIX cadastrados', value: PaymentMethod.REGISTERED_PIX },
  { label: 'PIX', value: PaymentMethod.PIX },
  { label: 'Boleto bancário', value: PaymentMethod.BANK_SLIP },
  { label: 'Conta bancária', value: PaymentMethod.BANK_ACCOUNT },
  { label: 'Pagamento Manual', value: PaymentMethod.MANUAL_PAYMENT },
];
export const ProcedureScreen = () => {
  const history = useHistory();
  const location = useLocation<{ prevPath: string }>();

  const { color } = useTheme();
  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isShowProductCancelModal, setIsShowProductCancelModal] = useState(false);
  const [isShowModalCreateBankAccount, setIsShowModalCreateBankAccount] = useState<boolean>(false);
  const [isLoadingBankAccount, setIsLoadingBankAccount] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [identification, setIdentification] = useState<string | null>(null);
  const [systemSteps, setSystemSteps] = useState<SystemStepCategory[]>([]);
  const { procedureId } = useParams<{ procedureId: string }>();
  const [banks, setBanks] = useState<string[]>([]);
  const { handleProcedureReload, handleSetStepFinance } = useAdmin();
  const [procedure, setProcedure] = useState<Financing>();
  const [listPaymentTypeOptions, setListPaymentTypeOptions] = useState<string[]>([]);
  const [kycError, setKycError] = useState<string>();

  const [clientData, setClientData] = useState<Client>();
  const [bankDetailsInProcedure, setBankDetailsInProcedure] = useState<BankAccountInFinancing>();
  const [contracts, setContracts] = useState<Contract[]>([]);

  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();
  const [selectedOption, setSelectedOption] = useState<AdminAnalyzeStatus | string | null>(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState<FinancingPaymentType>();
  const [selectedBank, setSelectedBank] = useState<string>();

  const [paymentListData, setPaymentListData] = useState<Payment[]>([]);
  const [reimbursementPaymentListData, setReimbursementPaymentListData] = useState<Payment[]>([]);
  const [paymentData, setPaymentData] = useState<PaymentData>();
  const [reimbursementData, setReimbursementData] = useState<ReimbursementData>();

  const getPaymentData = async () => {
    const paymentDetails = await financingService.getPaymentDetails(Number(procedureId));
    const paymentList = await financingService.listPayment(Number(procedureId)).then(response => {
      if (response?.procedureBankAccountType != null) {
        setSelectedBank(response.procedureBankAccountType);
      }

      return response;
    });
    const hasIuguTransfers = paymentList?.procedurePayment?.iuguTransfers?.length > 0;
    const hasBmpTransfers = paymentList?.procedurePayment?.bmpTransfers?.length > 0;
    const hasManualPayments = paymentList?.procedurePayment?.manualPayments?.length > 0;
    const hasReimbursement = paymentList?.procedureRefund?.procedureRefundPayments?.length > 0;
    if (hasIuguTransfers || hasManualPayments || hasBmpTransfers) {
      let newPaymentList: Payment[] = [
        ...(paymentList?.procedurePayment?.iuguTransfers || []),
        ...(paymentList?.procedurePayment?.manualPayments || []),
        ...(paymentList?.procedurePayment?.bmpTransfers || []),
      ];
      newPaymentList = newPaymentList.map(payment => {
        const newPayment = {
          value: Number(payment?.value).toFixed(2),
          errors: payment?.errors,
          status: payment?.status,
          ...(payment?.transferType && {
            procedurePaymentType: paymentOptions.find(paymentType => paymentType.value === payment?.transferType?.toUpperCase())?.label,
          }),
          ...(payment?.createdAt && { createdAt: payment?.createdAt }),
          ...(payment?.pixType && { pixType: pixOptions.find(pix => pix.value === payment?.pixType?.toUpperCase())?.label }),
          ...(payment?.pixKey && { pixKey: payment?.pixKey }),
          ...(payment?.receiptUrl && { receiptUrl: payment?.receiptUrl }),
          ...(payment?.receipt && { receipt: payment?.receipt }),
        };
        return newPayment;
      });

      setPaymentListData(newPaymentList);
    }

    if (hasReimbursement) {
      const newReimbursementPaymentList = paymentList?.procedureRefund?.procedureRefundPayments.map((payment: Payment) => {
        const newReimbursement = {
          value: Number(payment?.value).toFixed(2),
          attachments: payment?.attachments?.[0],
          status: payment?.paymentStatus,
        };
        return newReimbursement;
      });

      setReimbursementPaymentListData(newReimbursementPaymentList);
    }

    setPaymentData(paymentData => ({
      ...paymentData,
      totalValue: paymentDetails?.choosedValue?.toFixed(2),
      amountPaid: paymentList?.procedurePayment?.amountPaid?.toFixed(2),
      paymentStatus: paymentList?.procedurePayment?.procedurePaymentStatus,
      procedureBankAccountType: paymentDetails?.procedureBankAccountType,
      procedurePayment: paymentList?.procedurePayment,
      procedureRefund: paymentList?.procedureRefund,
    }));
    setReimbursementData(reimbursementData => ({
      ...reimbursementData,
      totalValue: paymentList?.procedureRefund?.amountPaid?.toFixed(2),
      amountPaid: paymentList?.procedureRefund?.amountPaid?.toFixed(2),
      paymentStatus: paymentList?.procedureRefund?.procedureRefundStatus,
    }));
  };
  const account = useSelector((state: IRootState) => state.authentication.account);

  const schema = yup.object().shape({
    action: yup.string().required('global.errorMessage.required'),
    comment: yup.string(),
    bank: yup.string().when({
      is: () => selectedPaymentType === FinancingPaymentType.CLIENT,
      then: schema => schema.required('global.errorMessage.required'),
    }),
    payment: yup.string().when('action', {
      is: t(`admin.customerAnalysis.analysisOptions.${AdminAnalyzeStatus.APPROVED}`),
      then: schema => schema.required(),
    }),
  });

  const methods = useForm<Schema>({ resolver: yupResolver(schema) });

  useEffect(() => {
    getProcedureIdentification();
    getOrganizationSteps();
    getBankAccountInProcedure();
    getListPaymentType();
    getAllContractsFinancing();
    getPaymentData();
  }, []);

  useEffect(() => {
    getBanks();
    if (procedure?.client?.id != null) {
      getClientData(procedure?.client?.id);
    }
    if (procedure?.financingStatus === FinancingStatusType.ERROR_ACCOUNT_IUGU) {
      getKYCError();
    }
  }, [procedure]);

  const getKYCError = () => {
    if (procedure?.client?.id) {
      iuguService
        .getCustomerSubAccount({ clientId: procedure?.client?.id })
        .then(response => {
          setKycError(response.msgErroKyc);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const getBankAccountInProcedure = () => {
    financingService.getFinancingBankAccount(Number(procedureId)).then(response => {
      setBankDetailsInProcedure(response.data);
      setSelectedPaymentType(response.data.financingPaymentType);
    });
  };

  const getAllContractsFinancing = async () => {
    if (procedureId) {
      const contractsRes = await financingService.getAllContractsFinancing(Number(procedureId));
      setContracts(contractsRes.content);
    }
  };

  const getClientData = (clientId: number) => {
    clientService.getAllClientData(clientId).then(setClientData);
  };

  const getStatus = (text: string) => {
    switch (text) {
      case t(`admin.customerAnalysis.analysisOptions.${AdminAnalyzeStatus.ADJUST}`):
        return AdminAnalyzeStatus.ADJUST;

      case t(`admin.customerAnalysis.analysisOptions.${AdminAnalyzeStatus.APPROVED}`):
        return AdminAnalyzeStatus.APPROVED;

      case t(`admin.customerAnalysis.analysisOptions.${AdminAnalyzeStatus.REJECTED}`):
        return AdminAnalyzeStatus.REJECTED;

      default:
        return '';
    }
  };

  const closeModal = () => {
    methods.setValue('action', '');
    methods.setValue('comment', '');
    methods.setValue('bank', '');
    methods.setValue('payment', '');
    setSelectedOption(null);
    setLoading(false);
    setShowModal(false);
    setIsShowProductCancelModal(false);
  };

  const sendAnalysis = async (values: Schema) => {
    setLoading(true);
    const status = getStatus(values.action);
    const paymentType = getPayment(values.payment);
    const bankAccountType = values.bank === t('admin.procedureScreen.clientPersonalAccount') ? TypesBanks.PERSONAL_ACCOUNT : values.bank;

    if (status) {
      const analyze: AdminAnalyze = {
        id: Number(procedureId),
        consultantComment: values.comment,
        financingStatus: status,
        procedureBankAccountType: isEmpty(bankAccountType) ? undefined : bankAccountType,
        financingPaymentType: paymentType,
      };

      const res = await financingService.adminAnalyzeProcedure(Number(procedureId), analyze);

      if (res.id) {
        setLoading(false);
        setShowModal(false);
        handleProcedureReload(true);

        if (analyze?.procedureBankAccountType === ProcedureBankAccountType.IUGU) {
          setPaymentData(paymentData => ({
            ...paymentData,

            procedureBankAccountType: ProcedureBankAccountType.IUGU,
          }));
        }

        if (
          paymentType === FinancingPaymentType.ORGANIZATION ||
          bankAccountType === TypesBanks.PERSONAL_ACCOUNT ||
          bankAccountType === TypesBanks.IUGU
        ) {
          setIsShowModalCreateBankAccount(true);
        }

        getProcedureIdentification();
        closeModal();
      }
    }
  };

  const getProcedureIdentification = async () => {
    const procedureRes = await financingService.getFinancingById(Number(procedureId));

    if (procedureRes.identification) {
      setIdentification(procedureRes.identification);
    }

    handleSetStepFinance(procedureRes);
    setProcedure(procedureRes);
  };

  const getOrganizationSteps = async () => {
    const steps = await organizationsSystemStepsService.getSteps(StepType.PROCEDURE);
    // tslint:disable-next-line: no-assign-mutated-array
    const systemStepsResponse = steps.sort(stepsOrdererByStepMomentAndOrderStep).map(step => step.systemStep.step);

    setSystemSteps(systemStepsResponse);
  };

  const getBanks = () => {
    financingService.getBanks().then(response => {
      const filteredBanks = response
        .filter(bank => {
          if (procedure?.onlyScrowAccount && bank === TypesBanks.PERSONAL_ACCOUNT) {
            return false;
          }

          if (procedure?.formalizationType === FormalizationType.BMP && bank === TypesBanks.IUGU) {
            return false;
          }

          if (procedure?.formalizationType === FormalizationType.GIRO && bank === TypesBanks.BMP) {
            return false;
          }

          return true;
        })
        .map(bank => (bank === TypesBanks.PERSONAL_ACCOUNT ? t('admin.procedureScreen.clientPersonalAccount') : bank));
      setBanks(filteredBanks);
    });
  };

  const getListPaymentType = () => {
    financingService.getListPaymentType().then(response => {
      const filteredListPaymentType = response
        .map(item => {
          if (item === FinancingPaymentType.ORGANIZATION && procedure?.formalizationType !== FormalizationType.BMP) {
            return t('admin.procedureScreen.paymentOptions.organization');
          } else if (item === FinancingPaymentType.CLIENT) {
            return t('admin.procedureScreen.paymentOptions.client');
          } else {
            return '';
          }
        })
        .filter(paymentType => paymentType !== '');

      setListPaymentTypeOptions(filteredListPaymentType);
    });
  };

  const cancelProduct = async () => {
    if (procedureId != null) {
      setLoading(true);
      financingService.cancelFinancing(Number(procedureId)).then(() => {
        setLoading(false);
        window.location.reload();
        setIsShowProductCancelModal(false);
      });
    }
  };

  const getPayment = (text?: string) => {
    switch (text) {
      case t('admin.procedureScreen.paymentOptions.organization'):
        return FinancingPaymentType.ORGANIZATION;

      default:
        return FinancingPaymentType.CLIENT;
    }
  };

  const textPaymentType = (text: FinancingPaymentType) => {
    switch (text) {
      case FinancingPaymentType.ORGANIZATION:
        return t('admin.procedureScreen.paymentOptions.organization');

      case FinancingPaymentType.CLIENT:
        return t('admin.procedureScreen.paymentOptions.organization');

      default:
        return t('admin.procedureScreen.paymentOptions.client');
    }
  };

  const bankType = (text?: string) => {
    switch (text) {
      case t('admin.procedureScreen.clientPersonalAccount'):
        return TypesBanks.PERSONAL_ACCOUNT;

      default:
        return text;
    }
  };

  interface Tabs {
    name: string;
    isValid: boolean;
  }

  const multtiploTabs: Tabs[] = [
    { name: t('admin.procedureScreen.tabs.details'), isValid: true },
    { name: t('admin.procedureScreen.tabs.contract'), isValid: true },
    { name: t('admin.procedureScreen.payments.procedurePayment'), isValid: procedure?.isPaid ?? false },
    { name: t('admin.procedureScreen.payments.reimbursement'), isValid: procedure?.isPaid ?? false },
  ];

  const getTabContent = () => {
    switch (tabSelected) {
      case 0:
        return <TabDetails procedure={procedure} getProcedure={() => getProcedureIdentification()} />;

      case 1:
        return <TabContracts procedure={procedure} contracts={contracts} />;

      case 2:
        return (
          <ServicePayment
            procedure={procedure}
            paymentData={paymentData}
            setPaymentData={setPaymentData}
            paymentListData={paymentListData}
            setPaymentListData={setPaymentListData}
            getPaymentData={getPaymentData}
          />
        );

      case 3:
        return (
          <ReimbursementPayment
            reimbursementData={reimbursementData}
            setReimbursementData={setReimbursementData}
            paymentListData={reimbursementPaymentListData}
            setPaymentListData={setReimbursementPaymentListData}
            getPaymentData={getPaymentData}
          />
        );
    }
  };

  return (
    <>
      <PageContainer>
        <Header variant={HeaderVariants.SECONDARY} />
        <TitleHeader>
          <Title>
            <button
              onClick={() => {
                if (location.state?.prevPath) {
                  history.push(location.state.prevPath);
                } else {
                  history.push(RoutePath.PROCEDURE_ADMIN);
                }
              }}
            >
              <FiChevronLeft color={color.primaryColor} />
            </button>
            <span className="title">{t('admin.procedureScreen.title')}</span>
            <span className="code">{identification ? `#${identification}` : ''}</span>
          </Title>

          <ButtonsContainer>
            {bankDetailsInProcedure?.bankAccount == null &&
              procedure?.formalizationType !== FormalizationType.BMP &&
              bankDetailsInProcedure?.financingPaymentType === FinancingPaymentType.CLIENT &&
              procedure?.financingStatus === FinancingStatusType.APPROVED && (
                <CustomButton onClick={() => setIsShowModalCreateBankAccount(true)}>
                  {t('admin.customerAnalysis.addBankDetails')}
                </CustomButton>
              )}
            {procedure?.financingStatus !== FinancingStatusType.CANCELLED && (
              <CustomButton className="cancel" onClick={() => setIsShowProductCancelModal(true)}>
                {t('admin.procedureScreen.cancelProduct')}
              </CustomButton>
            )}
            {(procedure?.financingStatus === FinancingStatusType.VALIDATION ||
              procedure?.financingStatus === FinancingStatusType.ADJUSTED) &&
              hasAuthorities(account?.authorities, [
                Authority.ROLE_ADMIN,
                Authority.ROLE_MANAGER,
                Authority.ROLE_SELLER,
                Authority.ROLE_ANALYST,
                Authority.ROLE_FINANCIAL,
                Authority.ROLE_INTERNAL_ADMIN,
              ]) &&
              clientData?.registrationStatus === RegistrationStatus.APPROVED && (
                <CustomButton onClick={() => setShowModal(true)}>{t('admin.procedureScreen.buttonText')}</CustomButton>
              )}
          </ButtonsContainer>
        </TitleHeader>
        {procedure?.financingStatus != null && (
          <StatusContainer>
            <Status text={t(`enum.filterAllStatus.${procedure.financingStatus}`)} color={procedure.financingStatus} />
          </StatusContainer>
        )}
        <StepBarFinanceProgress steps={systemSteps} finance={procedure} procedurePayment={paymentData} />
        {kycError && procedure?.financingStatus === FinancingStatusType.ERROR_ACCOUNT_IUGU ? (
          <KycErrorContainer>
            <ErrorIcon />
            <span className="message">{t('kyc.error', { errorMsg: kycError })}</span>
          </KycErrorContainer>
        ) : (
          <></>
        )}
        <Container>
          <TabHeaderContainer>
            <TabHeaderInnerContainer style={{ borderRight: '1px solid #E5E7E7', paddingRight: '16px', marginRight: '16px' }}>
              <TabTitleHeader>{t('admin.procedureScreen.clientName')}</TabTitleHeader>
              <TabClientName>{clientData?.physicalPerson?.name}</TabClientName>
            </TabHeaderInnerContainer>
            <TabHeaderInnerContainer style={{ borderRight: '1px solid #E5E7E7', paddingRight: '16px', marginRight: '16px' }}>
              <TabTitleHeader>{t('admin.procedureScreen.registerStatus')}</TabTitleHeader>
              <Status text={t(`enum.filterAllStatus.${clientData?.registrationStatus}`)} color={clientData?.registrationStatus ?? ''} />
            </TabHeaderInnerContainer>
            {bankDetailsInProcedure?.financingPaymentType != null && (
              <TabHeaderInnerContainer style={{ borderRight: '1px solid #E5E7E7', paddingRight: '16px', marginRight: '16px' }}>
                <TabTitleHeader>{t('admin.procedureScreen.paymentFor')}</TabTitleHeader>
                <Status
                  text={textPaymentType(bankDetailsInProcedure.financingPaymentType)}
                  color={bankDetailsInProcedure.financingPaymentType}
                />
              </TabHeaderInnerContainer>
            )}
            {contracts[0]?.status === ContractStatus.FINISHED && (
              <TabHeaderInnerContainer>
                <TabTitleHeader>{t('admin.procedureScreen.paymentStatus')}</TabTitleHeader>
                <Status
                  text={procedure?.isPaid ? t('admin.procedureScreen.confirmed') : t('admin.procedureScreen.pending')}
                  color={FinancingPaymentType.CLIENT}
                />
              </TabHeaderInnerContainer>
            )}
          </TabHeaderContainer>
          <TabBox>
            {multtiploTabs.map((tab, idx) =>
              tab?.isValid ? (
                <Tab key={`${tab?.name}${idx}`} $isSelected={tabSelected === idx} onClick={() => setTabSelected(idx)}>
                  {tab?.name}
                </Tab>
              ) : (
                <Tooltip title={t('proceduresDashboard.paymentRestricted') ?? ''} placement="top">
                  <Tab key={`${tab?.name}${idx}`}>
                    <AiOutlineInfoCircle />
                    {tab?.name}
                  </Tab>
                </Tooltip>
              )
            )}
          </TabBox>
          <TabContent>{getTabContent()}</TabContent>
        </Container>
      </PageContainer>

      {showModal && (
        <CustomModalTwoButtons
          isShowModal={showModal}
          onCloseModal={closeModal}
          firstButtonText={t('global.button.cancel')}
          secondButtonText={t('global.button.confirm')}
          handleClickFirstButton={closeModal}
          handleClickSecondButton={methods.handleSubmit(sendAnalysis)}
          title={t('admin.procedureScreen.modal.title')}
          large
          loading={loading}
        >
          <InputContent>
            <FormProvider {...methods}>
              <FormContainer>
                <SimpleSelectInput
                  name="action"
                  placeholder={t('admin.customerAnalysis.inputs.action')}
                  options={[
                    t(`admin.customerAnalysis.analysisOptions.${AdminAnalyzeStatus.ADJUST}`),
                    t(`admin.customerAnalysis.analysisOptions.${AdminAnalyzeStatus.APPROVED}`),
                    t(`admin.customerAnalysis.analysisOptions.${AdminAnalyzeStatus.REJECTED}`),
                  ]}
                  onSelectedOption={option => setSelectedOption(getStatus(option))}
                  errorMessage={methods.formState.errors.action?.message}
                />
                <InputText name="comment" placeholder={t('admin.customerAnalysis.inputs.comment')} />
                <SimpleSelectInput
                  name="payment"
                  placeholder={t('admin.customerAnalysis.inputs.payment')}
                  options={listPaymentTypeOptions}
                  onSelectedOption={option => setSelectedPaymentType(getPayment(option))}
                  errorMessage={methods.formState.errors.payment?.message}
                />
                {selectedOption === AdminAnalyzeStatus.APPROVED && selectedPaymentType === FinancingPaymentType.CLIENT && (
                  <SimpleSelectInput
                    name="bank"
                    placeholder={t('admin.customerAnalysis.inputs.bank')}
                    options={banks}
                    onSelectedOption={option => setSelectedBank(bankType(option))}
                  />
                )}
              </FormContainer>
            </FormProvider>
          </InputContent>
        </CustomModalTwoButtons>
      )}

      <CustomModalTwoButtons
        large
        formId="bank-account-creation-form"
        isShowModal={isShowModalCreateBankAccount}
        onCloseModal={() => {
          if (selectedPaymentType !== FinancingPaymentType.ORGANIZATION) {
            setIsShowModalCreateBankAccount(false);
          }
        }}
        title={t('dashboard.addBankDetails')}
        children={
          selectedPaymentType === FinancingPaymentType.CLIENT &&
          selectedBank === TypesBanks.PERSONAL_ACCOUNT &&
          procedure?.formalizationType !== FormalizationType.BMP ? (
            <BankDetailsRegistrationFormPix
              props={{
                clientData: clientData?.physicalPerson,
                selectedBankAccount,
                isRegistrationByAdmin: true,
                clientId: procedure?.client?.id,
                financingId: Number(procedureId),
                setSelectedBankAccount,
                setIsShowModalCreateBankAccount,
                setIsLoadingBankAccount,
                getBankAccount: () => {
                  getProcedureIdentification();
                  getBankAccountInProcedure();
                  getAllContractsFinancing();
                },
              }}
            />
          ) : (
            <BankAccountForm
              isRegistrationByAdmin
              clientData={clientData?.physicalPerson}
              clientId={procedure?.client?.id}
              paymentData={paymentData}
              financingId={Number(procedureId)}
              selectedBankAccount={selectedBankAccount}
              setSelectedBankAccount={setSelectedBankAccount}
              setIsShowModalCreateBankAccount={setIsShowModalCreateBankAccount}
              setIsLoadingBankAccount={setIsLoadingBankAccount}
              getBankAccount={() => {
                getProcedureIdentification();
                getBankAccountInProcedure();
                getAllContractsFinancing();
              }}
            />
          )
        }
        firstButtonText={t('global.button.cancel')}
        secondButtonText={t('global.button.add')}
        handleClickFirstButton={() => {
          if (selectedPaymentType !== FinancingPaymentType.ORGANIZATION) {
            setIsShowModalCreateBankAccount(false);
          }
        }}
        loading={isLoadingBankAccount}
      />

      <CustomModalTwoButtons
        isShowModal={isShowProductCancelModal}
        onCloseModal={() => closeModal()}
        firstButtonText={t('global.button.cancel')}
        secondButtonText={t('global.button.confirm')}
        handleClickFirstButton={() => closeModal()}
        handleClickSecondButton={() => cancelProduct()}
        title={t('admin.procedureScreen.cancelProductTitle')}
        large
        loading={loading}
      />
    </>
  );
};
