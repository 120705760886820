import iuguApi from 'api/iugu-api';
import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';

export const IuguService = () => {
  const getCustomerSubAccount = async (queryParams: { clientId?: number; organizationSubsidiaryId?: number }): Promise<IuguSubAccount> => {
    try {
      const result: AxiosResponse<IuguSubAccount> = await iuguApi.getCustomerSubAccount(queryParams);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    getCustomerSubAccount,
  };
};

export default IuguService();
