import { Address } from './address';
import { Attachment } from './attachment';
import { BankAccount, IPix } from './bank-account';
import { OrganizationData } from './organization-types';

export interface Subsidiary {
  id?: number;
  name?: string;
  assignmentFee?: string;
  phone?: string;
  email?: string;
  active?: boolean;
  cnpj?: string;
  organization?: OrganizationData;
  partnerCode?: string;
  paymentCompany?: PaymentCompany;
  onlyScrowAccount?: boolean;
  bankAccount?: BankAccount;
  address?: Address;
  retentionPercentage?: number;
  onlyRegisteredPixAllowed?: boolean;
  pixRegistrations?: IPix[];
  representative?: Representative;
  interestRate?: string;
  selfie?: Attachment;
  identification?: Attachment;
  socialContract?: Attachment;
  balanceSheet?: Attachment;
  proofResidence?: Attachment;
  status?: SubsidiaryStatus;
}

export enum SubsidiaryStatus {
  PENDING_CREATE_ACCOUNT_IUGU = 'PENDING_CREATE_ACCOUNT_IUGU',
  CREATING_ACCOUNT_IUGU = 'CREATING_ACCOUNT_IUGU',
  ACCOUNT_IUGU_CREATED = 'ACCOUNT_IUGU_CREATED',
  ERROR_ACCOUNT_IUGU = 'ERROR_ACCOUNT_IUGU',
}

export interface PaymentCompany {
  id: number;
  name: string;
}

export interface Representative {
  id?: number;
  name?: string;
  cpf?: string;
  rg?: string;
}
