import colors from './custom-organization/colors';

const lightBlack = '#3E3E3E';
const gray = '#979797';
const lightGray = '#D8D8D8';
const boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)';
const borderActive = '1px solid transparent';
const buttonBorderRadius = '6px';

export default {
  color: {
    ...colors,
    lightBlack,
    lightGray1: '#EEEEEE',
    lightGray2: '#E5E7E7',
    gray18: '#7F7F7F',
    gray19: '#4C4D4D',
    gray20: '#E5E7E7',
    gray21: '#333333',
    gray22: '#656666',
    gray23: '#CCCCCC',
    gray24: '#999999',
    black0: '#000',
    black1: '#010101',
    black1b: '#1B1A1B',
    pink: '#E40B53',
    orange: '#E42F0B',
    grayBackground: '#FAFAFA',
    new: '#6585E8',
    in_progress: '#F7B330',
    finished: '#0DB052',
    contract: '#0DB052',
    payment: '#0DB052',
    rejected: '#FC4F4F',
    canceled: '#CB0101',
    approved: '#0DB052',
    adjusted: '#0DB052',
    validation: '#F7B330',
    cancelled: '#CB0101',
    adjust: '#F6B330',
    white: '#FFFF',
    shadow: 'rgba(0, 0, 0, 0.06)',
    borderGray: '#EEEEEE',
    brightnessPrimary: colors.primaryColor + '10',
    statusNew: '#6585E8',
    verylightBlue: '#EFF6FF',
    green: '#0DB052',
    lightBlue: '#EDF1FF',
    lightRed: '#FFF4F2',
    pending_payment: '#F7B330',
    pending_sign: '#F7B330',
    grayLight: '#B3B2B3',
    lightRed2: '#FFEFEC',
    grayLight2: '#656666',
  },
  size: {
    small: '1px',
  },
  font: {
    default: 'Satoshi',
    title: 'SourceSans',
  },
  buttons: {
    white: {
      background: 'white',
      color: gray,
      border: `1px solid ${lightGray}`,
      borderRadius: buttonBorderRadius,
      boxShadow,
      lineHeight: `${42 / 16}rem`,
      padding: '0 20px',
      minWidth: '150px',
      fontSize: '1rem',
      // HOVER
      backgroundHover: lightGray,
      colorHover: lightBlack,
      boxShadowHover: boxShadow,
      // ACTIVE
      borderActive,
      boxShadowActive: 'none',
    },
  },
};
