import { AxiosResponse } from 'axios';
import { api } from './api';
import { buildUrl } from 'shared/util/api-utils';

const iuguApi = () => {
  const getCustomerSubAccount = (queryParams: { clientId?: number; organizationSubsidiaryId?: number }): Promise<AxiosResponse<any>> => {
    return api.get<IuguSubAccount>(buildUrl('/iugu-sub-accounts', queryParams));
  };

  return {
    getCustomerSubAccount,
  };
};

export default iuguApi();
