import ContainerDocument from 'components/container-document';
import ContainerUploadedDocument from 'components/container-uploaded-document';
import EnterCustomButton from 'components/enter-custom-button';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Button, ButtonsContainer } from 'components/register-forms/styles';
import { Attachment } from 'model/attachment';
import { Client } from 'model/client';
import { AttachmentType } from 'model/enums/attachment-types';
import { useRegisterForm } from 'provider/register-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { Container, DocumentContainer, IdentityContainer, Title } from './styles';

interface IResumeScreenProps {
  attachmentResume?: Attachment;
  actualStep: number;
  setActualStep: (step: number) => void;
  handleVerifyEmptyFields: (field: string) => boolean;
  isOnlyIdentificationDocument: boolean;
}

export const ResumeScreen: React.FC<IResumeScreenProps> = props => {
  const { t } = useTranslation();
  const { onClickNext, files, isLastForm, isLoading } = useRegisterForm();
  const schema = yup.object({
    attachmentIdentification: yup.mixed(),
    attachmentIncome: yup.mixed(),
    attachmentResidence: yup.mixed(),
  });
  const [requiredError, setRequiredError] = useState<string[]>([]);

  useEffect(() => {
    if (files.find(file => file.type === AttachmentType.PROOF_INCOME)) {
      setRequiredError([]);
    }
  }, [files]);

  const handleSubmit = () => {
    if (
      !props.handleVerifyEmptyFields(AttachmentType.PROOF_INCOME) ||
      !props.handleVerifyEmptyFields(AttachmentType.PROOF_RESIDENCE) ||
      !props.handleVerifyEmptyFields(AttachmentType.IDENTIFICATION_CARD)
    ) {
      onClickNext({ attachments: files } as Client);
    }
    if (props.handleVerifyEmptyFields(AttachmentType.PROOF_INCOME)) {
      setRequiredError(prevState => [...prevState, AttachmentType.PROOF_INCOME]);
    }
    if (props.handleVerifyEmptyFields(AttachmentType.PROOF_RESIDENCE)) {
      setRequiredError(prevState => [...prevState, AttachmentType.PROOF_RESIDENCE]);
    }
    if (props.handleVerifyEmptyFields(AttachmentType.IDENTIFICATION_CARD)) {
      setRequiredError(prevState => [...prevState, AttachmentType.IDENTIFICATION_CARD]);
    }
  };

  return (
    <ReactHookFormProvider schema={schema} onSubmit={() => handleSubmit()}>
      <Container>
        <DocumentContainer>
          <Title>{t('documents.identity.title')}</Title>
          <IdentityContainer>
            <ContainerDocument
              requiredError={requiredError.includes(AttachmentType.IDENTIFICATION_CARD)}
              titleDocument="documents.identification"
              editDocument
              nameInput="doc1"
              name="attachmentIdentification"
              type={AttachmentType.IDENTIFICATION_CARD}
              title={files && files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName}
              docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
            />
            <ContainerUploadedDocument editDocument attachmentType={AttachmentType.IDENTIFICATION_CARD} />
          </IdentityContainer>
        </DocumentContainer>
        {!props.isOnlyIdentificationDocument && (
          <>
            <DocumentContainer>
              <Title>{t('documents.proofs.incomeProof.title')}</Title>
              <IdentityContainer>
                <ContainerDocument
                  requiredError={requiredError.includes(AttachmentType.PROOF_INCOME)}
                  titleDocument="documents.identification"
                  editDocument
                  nameInput="doc2"
                  name="attachmentIncome"
                  type={AttachmentType.PROOF_INCOME}
                  title={files && files.find(att => att.type === AttachmentType.PROOF_INCOME)?.fileName}
                  docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.PROOF_INCOME))}
                />
                <ContainerUploadedDocument editDocument attachmentType={AttachmentType.PROOF_INCOME} />
              </IdentityContainer>
            </DocumentContainer>
          </>
        )}
        <DocumentContainer>
          <Title>{t('documents.proofs.residenceProof.title')}</Title>
          <IdentityContainer>
            <ContainerDocument
              requiredError={requiredError.includes(AttachmentType.PROOF_RESIDENCE)}
              titleDocument="documents.identification"
              editDocument
              nameInput="doc3"
              name="attachmentResidence"
              type={AttachmentType.PROOF_RESIDENCE}
              title={files && files.find(att => att.type === AttachmentType.PROOF_RESIDENCE)?.fileName}
              docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.PROOF_RESIDENCE))}
            />
            <ContainerUploadedDocument editDocument attachmentType={AttachmentType.PROOF_RESIDENCE} />
          </IdentityContainer>
        </DocumentContainer>
      </Container>
      <ButtonsContainer>
        {props.actualStep !== 0 ? (
          <Button onClick={() => props.setActualStep(props.actualStep - 1)}>
            <span>{t('global.button.backStep')}</span>
          </Button>
        ) : (
          <div />
        )}
        <EnterCustomButton
          type="submit"
          isLoading={isLoading}
          text={isLastForm() ? t('global.button.register') : t('global.button.nextStep')}
        />
      </ButtonsContainer>
    </ReactHookFormProvider>
  );
};
