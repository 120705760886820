import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import subsidiariesApi from 'api/subsidiaries-api';
import { PaymentCompany, Subsidiary } from 'model/subsidiaries';
import { OrganizationSubsidiaries } from 'model/landing-page';
import { PageableResponse } from 'model/pageable';

export const SubsidiariesService = () => {
  const getSubsidiariesByOrgId = async (orgId: number): Promise<Subsidiary[]> => {
    try {
      const result: AxiosResponse<Subsidiary[]> = await subsidiariesApi.getSubsidiariesByOrgId(orgId);
      if (result.data != null && result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getOrganizationSubsidiaries = async (
    searchName?: string,
    size?: number,
    page?: number,
    activeStatus?: string,
    sort?: string
  ): Promise<PageableResponse<Subsidiary>> => {
    try {
      const result: AxiosResponse<PageableResponse<Subsidiary>> = await subsidiariesApi.getOrganizationSubsidiaries(
        searchName,
        size,
        page,
        activeStatus,
        sort
      );
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const createSubsidiaries = async (newBranch: Subsidiary): Promise<Subsidiary> => {
    try {
      const result: AxiosResponse<Subsidiary> = await subsidiariesApi.createSubsidiaries(newBranch);
      if (result.data != null && result.status === HttpStatus.CREATED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getBranchInfos = async (id: number): Promise<Subsidiary> => {
    try {
      const result: AxiosResponse<Subsidiary> = await subsidiariesApi.getBranchInfos(id);
      if (result.data != null && result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const activateBranch = async (id: number): Promise<Subsidiary> => {
    try {
      const result: AxiosResponse<Subsidiary> = await subsidiariesApi.activateBranch(id);
      if (result.data != null && result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const inactivateBranch = async (id: number): Promise<Subsidiary> => {
    try {
      const result: AxiosResponse<Subsidiary> = await subsidiariesApi.inactivateBranch(id);
      if (result.data != null && result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const updateBranch = async (id: number, newBranch: Subsidiary): Promise<Subsidiary> => {
    try {
      const result: AxiosResponse<Subsidiary> = await subsidiariesApi.updateBranch(id, newBranch);
      if (result.data != null && result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getPaymentCompanies = async (): Promise<PaymentCompany[]> => {
    try {
      const result: AxiosResponse<PaymentCompany[]> = await subsidiariesApi.getPaymentCompanies();
      if (result.data != null && result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    getSubsidiariesByOrgId,
    getOrganizationSubsidiaries,
    createSubsidiaries,
    activateBranch,
    inactivateBranch,
    getBranchInfos,
    updateBranch,
    getPaymentCompanies,
  };
};

export default SubsidiariesService();
