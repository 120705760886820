import documentOcrApi from 'api/document-ocr-api';
import CardForDashboard from 'components/card-for-dashboard';
import { StyledButton } from 'components/custom-button/styles';
import CustomComponentModal from 'components/custom-conmponent-modal';
import DynamicDesktopLegalPersonEditForm from 'components/dynamic-legal-person-desktop-edit-form';
import PartnerViewButton from 'components/partner-view-button';
import { ReferenceCard } from 'components/reference-card';
import { ORGANIZATION_TYPE } from 'config/constants';
import { format, parse } from 'date-fns';
import { CardContent, CardText, IconBox } from 'features/admin/customerAnalysis/styles';
import { Status } from 'features/admin/dashboard/components/status';
import { useRedirect } from 'hooks/use-redirect/use-redirect';
import WhatsAppLogo from 'images/whatsapp.svg';
import _ from 'lodash';
import { Attachment } from 'model/attachment';
import { Client } from 'model/client';
import { CreateQueueDocFraud } from 'model/create-queue-doc-fraud';
import { CreditCard } from 'model/credit-card';
import { DTI } from 'model/dti';
import { AttachmentType } from 'model/enums/attachment-types';
import { Authority } from 'model/enums/authority';
import { BankAccountType } from 'model/enums/bank-account-type';
import { FormStepType } from 'model/enums/form-step-type';
import { GenderType } from 'model/enums/gender-type';
import { MailingDestinationType } from 'model/enums/mailing-destination-type';
import { MaritalStatus } from 'model/enums/marital-status';
import { NationalityType } from 'model/enums/nationality-type';
import { OrganizationType } from 'model/enums/organization-type';
import { PixType as InstantPaymentType } from 'model/enums/payment-type';
import { RegistrationStatus } from 'model/enums/registration-status';
import StepMoment from 'model/enums/step-moment';
import StepType from 'model/enums/step-type';
import SystemStepCategory from 'model/enums/system-step-category';
import { FaceMatchAndOCRAdminResponse } from 'model/face-match-and-ocr';
import { MeuCrediarioResponse, MultiCreditResponse } from 'model/multi-credit-response';
import { Organization } from 'model/organization';
import OrganizationsSystemSteps from 'model/organization-system-steps';
import { OwnCarType, ScholarityType } from 'model/physical-person';
import { VaduCreditEngine } from 'model/vadu-credit-engine';
import { EditFormProvider } from 'provider/edit-form';
import { SelectListsProvider } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCreditCard } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import clientDataService from 'services/client-data-service';
import clientService from 'services/client-service';
import organizationSystemStepsService from 'services/organization-system-steps-service';
import vaduService from 'services/vadu-service';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import StringUtils, { fillNumbers } from 'shared/util/string-utils';
import { hasAuthorities } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import Comments from '../comments';
import { CampDisplay } from './campDisplay';
import { CampContainer, CampContent, CampName, Separator } from './campDisplay/styles';
import { StepDisplay } from './stepDisplay';
import { StepsContainer, StyledSubtitle } from './styles';
export interface GenericFieldProps {
  name?: string;
  selfie?: boolean;
  isElement?: boolean;
  hide?: boolean;
  content?: Attachment | boolean | string | number | null;
  checkbox?: boolean;
  isFullLineField?: boolean;
  maxWindth?: string;
}

interface RegisterDisplay {
  registerData?: Client;
  myCredit?: MeuCrediarioResponse;
  organizationData?: Organization;
}

const removeSteps = [SystemStepCategory.PASSWORD, SystemStepCategory.CONTRACT_ACCOUNT_ACCEPT];
const replaceSteps = {
  from: [SystemStepCategory.PARTNER_RELATIONS, SystemStepCategory.PATRIMONIAL_SITUATION],
  to: SystemStepCategory.MY_COMPANY,
};

export const RegisterDataDisplay = ({ registerData, myCredit, organizationData }: RegisterDisplay) => {
  const { t } = useTranslation();
  const { color } = useTheme();
  const history = useHistory();
  const [steps, setSteps] = useState<OrganizationsSystemSteps[]>([]);
  const [selectedStep, setSelectedStep] = useState<SystemStepCategory | null>(null);
  const [camps, setCamps] = useState<GenericFieldProps[]>([]);
  const [faceMatchAndOCR, setFaceMatchAndOCR] = useState<FaceMatchAndOCRAdminResponse>({});
  const [multiCredit, setMultiCredit] = useState<MultiCreditResponse>({});
  const [dti, setDti] = useState<DTI | null>(null);
  const [creditCards, setCreditCards] = useState<CreditCard[] | null>(registerData?.creditCards ?? null);
  const [showComponentModal, setShowComponentModal] = useState(false);
  const [customComponent, setCustomComponent] = useState<JSX.Element>();
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;
  const { redirect } = useRedirect();

  const account = useSelector((state: IRootState) => state.authentication.account);

  const handleEditSelfie = () => {
    if (registerData?.id) {
      clientService.generateSelfieKeyAdmin(registerData?.id).then(res => {
        return history.replace(`/selfie/${registerData?.id}/admin/qrcode/${res?.selfieKey}`);
      });
    }
  };

  const handleSelfieAnalysis = (payload: CreateQueueDocFraud) => {
    if (registerData?.id) {
      clientService.selfieAnalysisAdmin(registerData?.id, payload).then(res => {
        return history.go(0);
      });
    }
  };

  const approveOcr = async () => {
    if (faceMatchAndOCR.documentOcr?.id) {
      await documentOcrApi.approveOcr(faceMatchAndOCR.documentOcr.id);
    }
  };

  const debounceHandleSelfieAnalysis = _.debounce(handleSelfieAnalysis, 500);

  const debounceApproveOcr = _.debounce(approveOcr, 500);

  const getStepName = (stepObj: OrganizationsSystemSteps) => {
    return t(`enums.formStep.${stepObj.systemStep.step}`);
  };

  const getCampName = (camp: string): string => {
    const campNamesToBeIgnored = ['id', 'ofAge', 'references', 'companyTimeInYears', 'birth', 'income', 'occupation'];
    return !campNamesToBeIgnored.includes(camp) ? t(`enums.campNames.${camp}`) : '';
  };

  const translateMaritalStatus = (status: MaritalStatus, gender: GenderType) => t(`enums.maritalStatus.${status}`, { context: gender });

  const translateGenderType = (gender: GenderType) => t(`enums.genderType.${gender}`);

  const translateNationalityType = (nationality: NationalityType) => t(`enums.nationalityType.${nationality}`);

  const translateMailingDestinationType = (type: MailingDestinationType) => t(`enums.mailingDestinationType.${type}`);

  const translateOwnCard = (type: OwnCarType) => t(`enums.ownCardType.${type}`);

  const translateScholarity = (type: ScholarityType) => t(`enums.scholarityType.${type}`);

  const translatePixType = (type?: InstantPaymentType) => t(`enums.pixType.${type}`);

  const personAdapter = (obj: string, target: string) => {
    if (getCampName(obj)) {
      if (getCampName(obj) === t('enums.campNames.isIncomeProven')) {
        return {};
      }
      if (getCampName(obj) === t(`enums.campNames.admissionDate`)) {
        return {};
      }
      if (getCampName(obj) === t(`enums.campNames.occupation`)) {
        if (registerData?.[target]?.[obj]?.name) {
          return { name: getCampName(obj), content: registerData?.[target]?.[obj]?.name };
        }
        return {};
      }
      if (getCampName(obj) === t(`enums.campNames.mainActivity`)) {
        if (registerData?.[target]?.[obj]?.name) {
          return { name: getCampName(obj), content: registerData?.[target]?.[obj]?.name };
        }
        return {};
      }
      if (getCampName(obj) === t(`enums.campNames.rg`)) {
        if (registerData![target][obj]) {
          return { name: getCampName(obj), content: StringUtils.rgMask(registerData![target][obj]) };
        }
        return {};
      }
      if (getCampName(obj) === t(`enums.campNames.cpf`)) {
        if (registerData![target][obj]) {
          return { name: getCampName(obj), content: StringUtils.cpfMask(registerData![target][obj] ?? '') };
        }
        return {};
      }
      if (getCampName(obj) === t(`enums.campNames.income`)) {
        if (registerData![target][obj]) {
          return { name: getCampName(obj), content: StringUtils.moneyMaskPtbr(registerData![target][obj] ?? '') };
        }
        return {};
      }
      if (getCampName(obj) === t(`enums.campNames.revenue`)) {
        if (registerData![target][obj]) {
          return { name: getCampName(obj), content: StringUtils.moneyMaskPtbr(registerData?.[target]?.[obj] ?? '') };
        }
        return {};
      }
      if (getCampName(obj) === t(`enums.campNames.netWorth`)) {
        if (registerData![target][obj]) {
          return { name: getCampName(obj), content: StringUtils.moneyMaskPtbr(registerData?.[target]?.[obj] ?? '') };
        }
        return {};
      }
      if (getCampName(obj) === t(`enums.campNames.cnpj`)) {
        return { name: getCampName(obj), content: StringUtils.cnpjMask(registerData?.[target]?.[obj] ?? '') };
      }
      if (getCampName(obj) === t(`enums.campNames.baseDate`)) {
        return {
          name: getCampName(obj),
          content: registerData?.[target]?.[obj] ? format(parse(registerData?.[target]?.[obj], 'yyyy-MM-dd', new Date()), 'MM/yyyy') : '',
        };
      }
      if (getCampName(obj) === t(`enums.campNames.formationDate`)) {
        return { name: getCampName(obj), content: StringUtils.dateFormatMask(registerData?.[target]?.[obj] ?? '') };
      }
      if (getCampName(obj) === t(`enums.campNames.phone`) || getCampName(obj) === 'phone') {
        return { name: getCampName(obj), content: StringUtils.phoneMask(registerData![target][obj]) };
      }
      if (getCampName(obj) === t(`enums.campNames.maritalStatus`)) {
        if (registerData![target][obj]) {
          return {
            name: getCampName(obj),
            content: translateMaritalStatus(registerData![target][obj].name, registerData![target]['genderType']),
          };
        }
        return {};
      }
      if (getCampName(obj) === t(`enums.campNames.genderType`)) {
        if (registerData![target][obj]) {
          return { name: getCampName(obj), content: translateGenderType(registerData![target][obj]) };
        }
        return {};
      }
      if (getCampName(obj) === t(`enums.campNames.nationalityType`)) {
        if (registerData![target][obj]) {
          return { name: getCampName(obj), content: translateNationalityType(registerData![target][obj]) };
        }
        return {};
      }

      if (getCampName(obj) === t(`enums.campNames.spouse`)) {
        if (registerData![target][obj]?.name) {
          return { name: getCampName(obj), content: registerData![target][obj].name };
        }
        return {};
      }

      if (getCampName(obj) === t(`enums.campNames.ownCar`)) {
        if (registerData![target][obj]) {
          return { name: getCampName(obj), content: translateOwnCard(registerData![target][obj]) };
        }
        return {};
      }

      if (getCampName(obj) === t(`enums.campNames.ownMotorbike`)) {
        if (registerData![target][obj]) {
          return { name: getCampName(obj), content: translateOwnCard(registerData![target][obj]) };
        }
        return {};
      }

      if (getCampName(obj) === t(`enums.campNames.scholarity`)) {
        if (registerData![target][obj]) {
          return { name: getCampName(obj), content: translateScholarity(registerData![target][obj]) };
        }
        return {};
      }

      if (getCampName(obj) === t(`enums.campNames.rgDateOfIssuance`)) {
        if (registerData![target][obj]) {
          return { name: getCampName(obj), content: StringUtils.dateFormatMask(registerData![target][obj]) };
        }
        return {};
      }
      if (getCampName(obj) === t(`enums.campNames.identityDocumentIssueDate`)) {
        if (registerData![target][obj]) {
          return { name: getCampName(obj), content: StringUtils.dateFormatMask(registerData![target][obj]) };
        }
        return {};
      }
      return { name: getCampName(obj), content: registerData![target][obj] };
    }
    return {};
  };

  const showRunAntiFraudForOcrNotDoneYet = false;

  const getCamps = (step: SystemStepCategory): GenericFieldProps[] => {
    switch (step) {
      case SystemStepCategory.PERSONAL_DATA:
      case SystemStepCategory.PERSONAL_DATA_MULTTIPLO:
      case SystemStepCategory.GENERAL_DATA: {
        return registerData?.legalPerson
          ? [
              { name: t('enums.campNames.responsibleName'), content: registerData?.legalPerson.responsibleName },
              personAdapter('formationDate', 'legalPerson'),
              personAdapter('cnpj', 'legalPerson'),
              { name: t('enums.campNames.corporateName'), content: registerData?.legalPerson.corporateName },
              personAdapter('mainActivity', 'legalPerson'),
              { name: t('enums.campNames.email'), content: registerData?.email },
              { name: t('enums.campNames.creditScore'), content: registerData?.creditScore },
            ].filter(elem => elem)
          : [
              ...Object.keys(registerData?.physicalPerson ?? [])
                .map(elem => {
                  return personAdapter(elem, 'physicalPerson');
                })
                .filter(elem => elem),
              {
                name: t('enums.campNames.birth'),
                content: StringUtils.dateFormatMask(String(registerData?.physicalPerson?.birth)),
              },
              {
                name: t('enums.campNames.age'),
                content: StringUtils.calculateAge(String(registerData?.physicalPerson?.birth)),
              },
              { name: t('enums.campNames.email'), content: registerData?.email },
              { name: t('enums.campNames.creditScore'), content: registerData?.creditScore },
              {
                name: t('enums.campNames.phone'),
                isElement: true,
                content: (
                  <CampContainer>
                    <>
                      <CampName>{t('enums.campNames.phone')}</CampName>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CampContent>{StringUtils.phoneMask(registerData?.phone)}</CampContent>
                        <a
                          style={{ marginBottom: '0.5rem', marginRight: '0.5rem' }}
                          href={`https://api.whatsapp.com/send?phone=${registerData?.phone}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={WhatsAppLogo} />
                        </a>
                      </div>
                      <Separator $red={false} />
                    </>
                  </CampContainer>
                ),
              },
              {},
            ];
      }
      case SystemStepCategory.ADDRESS:
      case SystemStepCategory.ADDRESS_MULTTIPLO: {
        return [
          ...Object.keys(registerData?.address ?? []).map(elem => {
            if (getCampName(elem)) {
              if (getCampName(elem) === t(`enums.campNames.city`)) {
                return { name: getCampName(elem), content: registerData?.['address']![elem].name };
              }
              if (getCampName(elem) === t(`enums.campNames.zipcode`)) {
                return { name: getCampName(elem), content: StringUtils.zipCodeMask(registerData?.['address']![elem]) };
              }
              if (getCampName(elem) === t(`enums.campNames.residenceType`)) {
                if (registerData?.['address']?.[elem]) {
                  return { name: getCampName(elem), content: StringUtils.replaceDisplayValue(registerData['address'][elem].displayValue) };
                }
                return {};
              }
              if (getCampName(elem) === t(`enums.campNames.mailingDestinationType`)) {
                if (registerData?.['address']?.[elem]) {
                  return { name: getCampName(elem), content: translateMailingDestinationType(registerData['address'][elem]) };
                }
                return {};
              }
              if (getCampName(elem) === t('enums.campNames.referencePoint')) {
                if (registerData?.['address']?.[elem]) {
                  return { name: getCampName(elem), content: registerData['address'][elem] };
                }

                return {};
              }
              return { name: getCampName(elem), content: registerData?.['address']![elem] };
            } else {
              return {};
            }
          }),
          { name: t(`register.inputs.state`), content: registerData?.address?.city?.state?.name },
        ];
      }
      case SystemStepCategory.INCOME: {
        return [
          {
            name: registerData?.employmentBond ? getCampName('employmentBond') : '',
            content: registerData?.employmentBond ? t(`enums.employmentBonds.${registerData?.['employmentBond']}`).toString() : null,
          },
          {
            name: registerData?.physicalPerson?.occupation?.name ? t('enums.campNames.occupation') : '',
            content: registerData?.physicalPerson?.occupation?.name ?? null,
          },
          {
            name: registerData?.physicalPerson?.admissionDate ? t('enums.campNames.income') : '',
            content: registerData?.physicalPerson?.admissionDate
              ? StringUtils.dateFormatMask(registerData?.physicalPerson?.admissionDate.toLocaleString())
              : null,
          },
          {
            name: registerData?.physicalPerson?.income ? t('enums.campNames.income') : '',
            content: registerData?.physicalPerson?.income ? StringUtils.moneyMaskPtbr(registerData?.physicalPerson?.income) : null,
          },
          {
            name: registerData?.physicalPerson?.companyTimeInYears != null ? t('enums.campNames.companyTimeInYears') : '',
            content:
              registerData?.physicalPerson?.companyTimeInYears != null ? String(registerData?.physicalPerson?.companyTimeInYears) : null,
          },
        ];
      }
      case SystemStepCategory.PHYSICAL_PERSON_DOCUMENTS:
      case SystemStepCategory.LEGAL_PERSON_DOCUMENTS: {
        return registerData?.attachments
          ? [
              ...registerData.attachments.map(elem => {
                if (elem.type !== FormStepType.SELFIE && elem?.type) {
                  return { name: getCampName(elem?.type), content: elem };
                }
                return { name: undefined };
              }),
            ]
          : [];
      }
      case SystemStepCategory.DOC_FRAUD_ANALYSIS: {
        return faceMatchAndOCR.documentOcr != null
          ? [
              {
                name: t('enums.faceOrOCR.generalDataConsultation'),
                isElement: true,
                content: <StyledSubtitle>{t('enums.faceOrOCR.generalDataConsultation')}</StyledSubtitle>,
              },
              {
                name: t('enums.faceOrOCR.analysisResult'),
                content: faceMatchAndOCR?.documentOcr?.internalComment,
                isFullLineField: true,
                maxWindth: '628px',
              },
              {
                name: t('enums.faceOrOCR.doneTriesQuantity'),
                content: registerData?.ocrProcessingTries,
              },
              {
                name: t('enums.faceOrOCR.availableTriesQuantity'),
                content: organizationData?.numberOfDocFraudCalls,
              },
              {
                name: t('enums.faceOrOCR.issuedAt'),
                content: faceMatchAndOCR.documentOcr?.issuedAt
                  ? StringUtils.dateFormatMask(faceMatchAndOCR.documentOcr?.issuedAt.toLocaleString())
                  : '',
              },
              {
                name: t('enums.faceOrOCR.executeAntiFraud'),
                isElement: true,
                hide: faceMatchAndOCR.documentOcr?.isVerified || !faceMatchAndOCR.documentOcr?.canRetry,
                content: (registerData?.registrationStatus === RegistrationStatus.ADJUSTED ||
                  registerData?.registrationStatus === RegistrationStatus.VALIDATION) &&
                  (organizationData?.numberOfDocFraudCalls ?? 0) > (registerData.ocrProcessingTries ?? 0) && (
                    <div style={{ width: '100%', display: 'flex', maxWidth: '250px' }}>
                      <StyledButton
                        $justifyContent="center"
                        $maxWidth="300px"
                        $width="90%"
                        onClick={() =>
                          debounceHandleSelfieAnalysis({
                            createActionForOcr: true,
                            createActionForFaceMatch: false,
                          })
                        }
                      >
                        {t('enums.faceOrOCR.executeAntiFraud')}
                      </StyledButton>
                    </div>
                  ),
              },
              {
                name: t('enums.faceOrOCR.approveOcr'),
                isElement: true,
                hide:
                  (registerData?.registrationStatus !== RegistrationStatus.ADJUSTED &&
                    registerData?.registrationStatus !== RegistrationStatus.ADJUST &&
                    !hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN])) ||
                  faceMatchAndOCR.documentOcr?.isVerified,
                content: registerData?.registrationStatus === RegistrationStatus.ADJUSTED &&
                  (organizationData?.numberOfDocFraudCalls ?? 0) > (registerData.ocrProcessingTries ?? 0) && (
                    <div style={{ display: 'flex', width: '100%', maxWidth: '250px' }}>
                      <StyledButton $justifyContent="center" $maxWidth="300px" $width="90%" onClick={() => debounceApproveOcr()}>
                        {t('enums.faceOrOCR.approveOcr')}
                      </StyledButton>
                    </div>
                  ),
              },
              {
                name: t('enums.faceOrOCR.documentExtractionData'),
                isElement: true,
                content: <StyledSubtitle>{t('enums.faceOrOCR.documentExtractionData')}</StyledSubtitle>,
              },
              {
                name: t('enums.faceOrOCR.documentName'),
                content: faceMatchAndOCR.documentOcr?.enhancedSchemaName
                  ? t(`enums.faceOrOCR.${faceMatchAndOCR.documentOcr?.enhancedSchemaName.toLowerCase()}`).toString()
                  : null,
              },
              { name: t('enums.faceOrOCR.documentNumber'), content: faceMatchAndOCR.documentOcr?.enhancedPersonTaxId },
              {
                name: t('enums.faceOrOCR.clientName'),
                content: faceMatchAndOCR.documentOcr?.enhancedPersonName,
              },
              {
                name: t('enums.faceOrOCR.birthDay'),
                content: faceMatchAndOCR.documentOcr?.enhancedPersonBirthdate
                  ? StringUtils.dateFormatMask(faceMatchAndOCR.documentOcr?.enhancedPersonBirthdate.toLocaleString())
                  : null,
              },
              { name: t('enums.faceOrOCR.motherName'), content: faceMatchAndOCR.documentOcr?.enhancedPersonMothersName },
              {
                name: t('enums.faceOrOCR.fatherName'),
                content: faceMatchAndOCR.documentOcr?.enhancedPersonFathersName ?? registerData?.physicalPerson?.fatherName,
              },

              {
                name: t('enums.faceOrOCR.RevenueData'),
                isElement: true,
                content: <StyledSubtitle>{t('enums.faceOrOCR.RevenueData')}</StyledSubtitle>,
              },

              {
                name: t('enums.faceOrOCR.documentNumber'),
                content: faceMatchAndOCR.documentOcr?.taxDataId,
              },
              {
                name: t('enums.faceOrOCR.documentName'),
                content: faceMatchAndOCR.documentOcr?.taxDataName,
              },
              {
                name: t('enums.faceOrOCR.motherName'),
                content: faceMatchAndOCR.documentOcr?.taxDataMothersName,
              },
              {
                name: t('enums.faceOrOCR.birthDay'),
                content: faceMatchAndOCR.documentOcr?.taxDataBirthdate
                  ? StringUtils.dateFormatMask(faceMatchAndOCR.documentOcr?.taxDataBirthdate.toLocaleString())
                  : null,
              },
              {
                name: t('enums.faceOrOCR.MatchingDocumentAndRevenueData'),
                isElement: true,
                content: <StyledSubtitle>{t('enums.faceOrOCR.MatchingDocumentAndRevenueData')}</StyledSubtitle>,
              },

              { name: t('enums.faceOrOCR.matchesName'), content: faceMatchAndOCR.documentOcr?.matchesName },
              {
                name: t('enums.faceOrOCR.matchesMothersName'),
                content: faceMatchAndOCR.documentOcr?.matchesMothersName,
              },
              {
                name: t('enums.faceOrOCR.matchesBirthdate'),
                content: faceMatchAndOCR.documentOcr?.matchesBirthdate,
              },
              {
                name: t('enums.faceOrOCR.classificationResult'),
                isElement: true,
                content: <StyledSubtitle>{t('enums.faceOrOCR.classificationResult')}</StyledSubtitle>,
              },

              {
                name: t('enums.faceOrOCR.typeClassification'),
                content: faceMatchAndOCR.documentOcr?.classificationType
                  ? t(`enums.faceOrOCR.${faceMatchAndOCR.documentOcr?.classificationType.toLowerCase()}`).toString()
                  : null,
              },
              {
                name: t('enums.faceOrOCR.subTypeClassification'),
                content: faceMatchAndOCR.documentOcr?.classificationSubtype
                  ? t(`enums.faceOrOCR.${faceMatchAndOCR.documentOcr?.classificationSubtype}`).toString()
                  : null,
              },
              {
                name: t('enums.faceOrOCR.countryClassification'),
                content: faceMatchAndOCR.documentOcr?.classificationCountry,
              },
              {
                name: t('enums.faceOrOCR.sideClassification'),
                content: faceMatchAndOCR.documentOcr?.classificationSide
                  ? t(`enums.faceOrOCR.${faceMatchAndOCR.documentOcr?.classificationSide.toLowerCase()}`).toString()
                  : null,
              },
              {
                name: t('enums.faceOrOCR.matchesNaclassificationSameImageme'),
                content: faceMatchAndOCR.documentOcr?.classificationSameImage,
              },
              {
                name: t('enums.faceOrOCR.confidence'),
                content: faceMatchAndOCR.documentOcr?.details?.[0]?.confidence
                  ? `${faceMatchAndOCR.documentOcr?.details?.[0]?.confidence * 100} %`
                  : null,
              },
            ]
          : [
              {
                name: t('enums.faceOrOCR.generalDataConsultation'),
                isElement: true,
                content: <StyledSubtitle>{t('enums.faceOrOCR.generalDataConsultation')}</StyledSubtitle>,
              },
              {
                name: t('enums.faceOrOCR.doneTriesQuantity'),
                content: registerData?.ocrProcessingTries ?? 0,
              },
              {
                name: t('enums.faceOrOCR.availableTriesQuantity'),
                content: organizationData?.numberOfDocFraudCalls ?? 0,
              },
              {
                name: t('enums.faceOrOCR.executeAntiFraud'),
                isElement: true,
                hide: !showRunAntiFraudForOcrNotDoneYet,
                content: (registerData?.registrationStatus === RegistrationStatus.ADJUSTED ||
                  registerData?.registrationStatus === RegistrationStatus.VALIDATION) &&
                  (organizationData?.numberOfDocFraudCalls ?? 0) > (registerData.ocrProcessingTries ?? 0) && (
                    <div style={{ width: '100%', display: 'flex', maxWidth: '250px' }}>
                      <StyledButton
                        $justifyContent="center"
                        $maxWidth="300px"
                        $width="90%"
                        onClick={() =>
                          debounceHandleSelfieAnalysis({
                            createActionForOcr: true,
                            createActionForFaceMatch: false,
                          })
                        }
                      >
                        {t('enums.faceOrOCR.executeAntiFraud')}
                      </StyledButton>
                    </div>
                  ),
              },
            ];
      }
      case SystemStepCategory.FRAUD_ANALYSIS_SELFIE: {
        return faceMatchAndOCR.faceMatch != null && registerData?.attachments
          ? [
              {
                name: t('enums.faceOrOCR.generalDataConsultation'),
                isElement: true,
                content: <StyledSubtitle>{t('enums.faceOrOCR.generalDataConsultation')}</StyledSubtitle>,
              },
              {
                name: t('enums.faceOrOCR.analysisResult'),
                content: faceMatchAndOCR?.faceMatch?.internalComment,
              },
              {
                name: t('enums.faceOrOCR.doneTriesQuantity'),
                content: registerData?.faceMatchedProcessingTries ?? 0,
              },
              {
                name: t('enums.faceOrOCR.availableTriesQuantity'),
                content: organizationData?.numberOfDocFraudCalls ?? 0,
              },
              {
                name: t('enums.faceOrOCR.executeAntiFraud'),
                isElement: true,
                hide: !(faceMatchAndOCR.faceMatch?.canRetry ?? true) || (faceMatchAndOCR.faceMatch?.isVerified ?? false),
                content: (((registerData?.registrationStatus === RegistrationStatus.ADJUSTED ||
                  registerData.registrationStatus === RegistrationStatus.VALIDATION) &&
                  faceMatchAndOCR.documentOcr?.isVerified) ||
                  (registerData?.registrationStatus === RegistrationStatus.REJECTED && !faceMatchAndOCR.documentOcr?.isVerified)) &&
                  (organizationData?.numberOfDocFraudCalls ?? 0) > (registerData.faceMatchedProcessingTries ?? 0) && (
                    <div style={{ width: '100%', display: 'flex' }}>
                      <StyledButton
                        $justifyContent="center"
                        $maxWidth="300px"
                        $width="90%"
                        onClick={() =>
                          handleSelfieAnalysis({
                            createActionForOcr: false,
                            createActionForFaceMatch: true,
                          })
                        }
                      >
                        {t('enums.faceOrOCR.executeAntiFraud')}
                      </StyledButton>
                    </div>
                  ),
              },
              ...(registerData && registerData.attachments
                ? registerData.attachments.map(elem => {
                    if (elem.type === FormStepType.SELFIE) {
                      return { name: getCampName(elem.type), content: elem };
                    }
                    if (elem.type === AttachmentType.IDENTIFICATION_CARD) {
                      return { name: getCampName(elem.type), content: elem };
                    }
                    return {};
                  })
                : []),
              {
                name: t('enums.faceOrOCR.confidence'),
                content: faceMatchAndOCR.faceMatch?.confidence?.toFixed(2),
              },
              { name: t('enums.faceOrOCR.matchesImage'), content: faceMatchAndOCR.faceMatch?.matched ? true : false },
            ]
          : [
              {
                name: t('enums.faceOrOCR.generalDataConsultation'),
                isElement: true,
                content: <StyledSubtitle>{t('enums.faceOrOCR.generalDataConsultation')}</StyledSubtitle>,
              },
              {
                name: t('enums.faceOrOCR.analysisResult'),
                content: faceMatchAndOCR?.faceMatch?.internalComment,
              },
              {
                name: t('enums.faceOrOCR.doneTriesQuantity'),
                content: registerData?.faceMatchedProcessingTries ?? 0,
              },
              {
                name: t('enums.faceOrOCR.availableTriesQuantity'),
                content: organizationData?.numberOfDocFraudCalls ?? 0,
              },
              {
                name: t('enums.faceOrOCR.executeAntiFraud'),
                isElement: true,
                hide: !(faceMatchAndOCR.faceMatch?.canRetry ?? true) || (faceMatchAndOCR.faceMatch?.isVerified ?? false),
                content: (registerData?.registrationStatus === RegistrationStatus.ADJUSTED ||
                  registerData?.registrationStatus === RegistrationStatus.VALIDATION) &&
                  faceMatchAndOCR.documentOcr?.isVerified &&
                  (organizationData?.numberOfDocFraudCalls ?? 0) > (registerData.faceMatchedProcessingTries ?? 0) && (
                    <div style={{ width: '100%', display: 'flex' }}>
                      <StyledButton
                        $justifyContent="center"
                        $maxWidth="300px"
                        $width="90%"
                        onClick={() =>
                          handleSelfieAnalysis({
                            createActionForOcr: false,
                            createActionForFaceMatch: true,
                          })
                        }
                      >
                        {t('enums.faceOrOCR.executeAntiFraud')}
                      </StyledButton>
                    </div>
                  ),
              },
            ];
      }
      case SystemStepCategory.SCORE_ANALYSIS: {
        return [
          {
            name: t('enums.campNames.flagDtNascimentoValidado'),
            content: multiCredit?.flagDtNascimentoValidado,
          },
          {
            name: t('enums.campNames.flagNomeMaeValidado'),
            content: multiCredit?.flagNomeMaeValidado,
          },
          {
            name: t('enums.campNames.flagNomeValidado'),
            content: multiCredit?.flagNomeValidado,
          },
          {
            name: t('enums.campNames.flagTelefoneValidado'),
            content: multiCredit?.flagTelefoneValidado,
          },
          {
            name: t('enums.campNames.grupoRisco'),
            content: multiCredit?.grupoRisco,
          },
          {
            name: t('enums.campNames.limiteGeral'),
            content: multiCredit?.limiteGeral ? StringUtils.currencyPtBrMask(multiCredit?.limiteGeral) : null,
          },
          {
            name: t('enums.campNames.limiteMensal'),
            content: multiCredit?.limiteMensal ? StringUtils.currencyPtBrMask(multiCredit?.limiteMensal) : null,
          },
          {
            name: t('enums.campNames.qtdePassagens90Dias'),
            content: multiCredit?.qtdePassagens90Dias,
          },
          {
            name: t('enums.campNames.qtdeProtestos'),
            content: multiCredit?.qtdeProtestos,
          },
          {
            name: t('enums.campNames.qtdeRestritivos'),
            content: multiCredit?.qtdeRestritivos,
          },
          {
            name: t('enums.campNames.scoreCredito'),
            content: multiCredit?.scoreCredito,
          },
          {
            name: t('enums.campNames.scoreMercado'),
            content: multiCredit?.scoreMercado,
          },
          {
            name: t('enums.campNames.situacaoReceita'),
            content: multiCredit?.situacaoReceita,
          },
          {
            name: t('enums.campNames.creditEngine'),
            content: multiCredit?.creditEngine?.status && t(`enum.registrationStatus.${multiCredit?.creditEngine?.status}`).toUpperCase(),
          },
          {
            name: t('enums.campNames.vvlComprometimentoMensal'),
            content: multiCredit?.vvlComprometimentoMensal,
          },
          {
            name: t('enums.campNames.vvlComprometimentoTotal'),
            content: multiCredit?.vvlComprometimentoTotal,
          },
        ];
      }
      case SystemStepCategory.SELFIE: {
        if (!registerData?.attachments?.find(elem => elem.type === FormStepType.SELFIE)) {
          if (registerData?.selfieKey) {
            return [
              {
                name: t('enums.campNames.SELFIE'),
                isElement: true,
                content: (
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <StyledButton
                      $justifyContent="center"
                      $maxWidth="300px"
                      $width="90%"
                      onClick={() => history.replace(`/selfie/${registerData?.id}/admin/qrcode/${registerData?.selfieKey}`)}
                    >
                      {t('selfie.title')}
                    </StyledButton>
                  </div>
                ),
              },
            ];
          } else if (
            registerData?.registrationStatus !== RegistrationStatus.NEW &&
            registerData?.registrationStatus !== RegistrationStatus.PRE_REJECTED &&
            registerData?.id
          ) {
            return [
              {
                name: t('enums.campNames.SELFIE'),
                isElement: true,
                content: (
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <StyledButton
                      $justifyContent="center"
                      $maxWidth="300px"
                      $width="90%"
                      onClick={() => {
                        if (registerData.id) {
                          clientService.generateSelfieKeyAdmin(registerData.id).then(res => {
                            return history.replace(`/selfie/${registerData.id}/admin/qrcode/${res?.selfieKey}`);
                          });
                        }
                      }}
                    >
                      {t('selfie.title')}
                    </StyledButton>
                  </div>
                ),
              },
            ];
          }
        }
        return [
          {
            name: t('enums.campNames.SELFIE'),
            content: registerData?.attachments?.find(elem => elem.type === FormStepType.SELFIE),
            selfie: true,
          },
        ];
      }

      case SystemStepCategory.BANK_ACCOUNT_DATA: {
        const arrayIndex: number = !Object.prototype.hasOwnProperty.call(registerData?.bankAccounts?.[0], 'bank') ? 1 : 0;
        const arrayIndexPix: number = !Object.prototype.hasOwnProperty.call(registerData?.bankAccounts?.[0], 'bank') ? 0 : 1;

        return [
          {
            name: t('enums.campNames.bank'),
            content: `${registerData?.bankAccounts?.[arrayIndex]?.bank?.number ?? ''}${
              registerData?.bankAccounts?.[arrayIndex]?.bank?.name ? ` - ${registerData?.bankAccounts?.[arrayIndex]?.bank?.name}` : ''
            }`,
          },
          {
            name: t('enums.campNames.agency'),
            content: `${fillNumbers(registerData?.bankAccounts?.[arrayIndex]?.agencyNumber ?? '') ?? ''}${
              registerData?.bankAccounts?.[arrayIndex]?.agencyDigit ? `-${registerData?.bankAccounts?.[arrayIndex]?.agencyDigit}` : ''
            }`,
          },
          {
            name: t('enums.campNames.account'),
            content: `${registerData?.bankAccounts?.[arrayIndex]?.bankAccountNumber}${
              registerData?.bankAccounts?.[arrayIndex]?.bankAccountDigit
                ? `-${registerData?.bankAccounts?.[arrayIndex]?.bankAccountDigit}`
                : ''
            }`,
          },
          {
            name: t('enums.campNames.accountType'),
            content: registerData?.bankAccounts?.[arrayIndex]?.type
              ? BankAccountType[registerData?.bankAccounts?.[arrayIndex]?.type ?? 0]
              : '',
          },
          { name: t('enums.campNames.jointAccount'), content: false, checkbox: true },
          {
            name: registerData?.bankAccounts?.[arrayIndexPix]?.pixType ? t('enums.campNames.pixType') : '',
            content: registerData?.bankAccounts?.[arrayIndexPix]?.pixType
              ? translatePixType(registerData?.bankAccounts?.[arrayIndexPix]?.pixType)
              : null,
          },
          {
            name: registerData?.bankAccounts?.[arrayIndexPix]?.pixKey ? t('enums.campNames.pixKey') : '',
            content: registerData?.bankAccounts?.[arrayIndexPix]?.pixKey ? registerData?.bankAccounts?.[arrayIndexPix]?.pixKey : null,
          },
        ];
      }

      case SystemStepCategory.SCORE_ANALYSIS_DTI: {
        return [
          { name: t('enums.campNames.codeTransaction'), content: dti?.id },
          { name: t('enums.campNames.orderCode'), content: dti?.orderId },
          { name: t('enums.campNames.consultedCpf'), content: StringUtils.cpfMask(registerData?.physicalPerson?.cpf) },
          {
            name: t('enums.campNames.referenceDate'),
            content: dti?.creationDate ? formatDateToLocalFormat(dti.creationDate) : undefined,
          },
          {
            name: t('enums.campNames.transactionDate'),
            content: dti?.creationDate ? formatDateToLocalFormat(dti.creationDate) : undefined,
          },
          { name: t('enums.campNames.score'), content: dti?.score },
          { name: t('enums.campNames.digitalPresence'), content: dti?.digital },
          { name: t('enums.campNames.consumptionPotential'), content: dti?.goodFor },
          { name: t('enums.campNames.qualityAndVariety'), content: dti?.varietyIndex },
          { name: t('enums.campNames.paymentMethods'), content: dti?.behaviourIndex },
          { name: t('enums.campNames.profile'), content: dti?.profileIndex },
          { name: t('enums.campNames.clientData'), content: dti?.statusIndex },
          { name: t('enums.campNames.cepIndex'), content: dti?.postalIndex },
          { name: t('enums.campNames.cpfScores'), content: dti?.rapportIndex },
        ];
      }
      case SystemStepCategory.PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT: {
        return [
          {
            name: t('enums.campNames.IDENTIFICATION_CARD'),
            content: registerData?.attachments?.find(elem => elem.type === AttachmentType.IDENTIFICATION_CARD),
          },
          {
            name: t('enums.campNames.PROOF_RESIDENCE'),
            content: registerData?.attachments?.find(elem => elem.type === AttachmentType.PROOF_RESIDENCE),
          },
        ];
      }
      case SystemStepCategory.MY_COMPANY: {
        return [
          personAdapter('baseDate', 'legalPerson'),
          personAdapter('netWorth', 'legalPerson'),
          personAdapter('revenue', 'legalPerson'),
          ...(registerData?.legalPerson?.partners ?? []).map((partner, index) => ({
            name: `partner`,
            isElement: true,
            content: (
              <PartnerViewButton
                partner={partner}
                index={index}
                onClick={() => {
                  setShowComponentModal(true);
                  setCustomComponent(
                    <SelectListsProvider>
                      <EditFormProvider>
                        <DynamicDesktopLegalPersonEditForm onlyView isOnModal editingPartner partner={partner} />
                      </EditFormProvider>
                    </SelectListsProvider>
                  );
                }}
              />
            ),
          })),
        ];
      }
      case SystemStepCategory.CREDIT_CARD: {
        return [
          {
            name: t('enums.campNames.creditCard'),
            isElement: true,
            content: (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {creditCards?.map((creditCard, key) => (
                  <CardForDashboard
                    key={key}
                    isActive
                    icon={
                      <IconBox>
                        <FiCreditCard size={20} color={color.primaryColor} />
                      </IconBox>
                    }
                    title={t('admin.customerAnalysis.stepCards.creditCard')}
                    content={
                      <CardContent>
                        <div className="status">
                          <Status text={t(`enum.filterAllStatus.${creditCard?.status}`)} color={creditCard?.status ?? 'undefined'} />
                        </div>
                        <CardText className="credit">
                          {t('admin.customerAnalysis.textStepCards.creditCard', { number: creditCard?.number })}
                        </CardText>
                      </CardContent>
                    }
                    arrowButton
                    onHandleClick={() => {
                      const redirect = `/admin/cadastros/${registerData?.id}/cartao/${creditCard.id}`;
                      if (redirect) {
                        history.push(redirect, { prevPath: history.location.pathname });
                      }
                    }}
                  />
                ))}
              </div>
            ),
          },
        ];
      }
      case SystemStepCategory.COMMENTS: {
        return [
          {
            isElement: true,
            content: <Comments clientId={registerData?.id?.toString() ?? ''} />,
          },
        ];
      }
      default: {
        return [];
      }
    }
  };

  useEffect(() => {
    organizationSystemStepsService.getAllRegistrationSteps().then(res => {
      const dataSteps = res.data;
      const toReplace = dataSteps.findIndex(elem => replaceSteps.from.includes(elem.systemStep.step));
      let newSteps = dataSteps
        .filter(elem => !removeSteps.includes(elem.systemStep.step))
        .map((elem, index) =>
          index === toReplace ? ({ ...elem, systemStep: { step: replaceSteps.to } } as OrganizationsSystemSteps) : elem
        )
        .filter(elem => !replaceSteps.from.includes(elem.systemStep.step))
        .map((elem, index) => {
          return { ...elem, selectionId: index, name: getStepName(elem) };
        });

      setSteps(newSteps);
    });
  }, [registerData]);

  useEffect(() => {
    if (selectedStep != null) {
      setCamps(getCamps(selectedStep).filter(elem => elem.content != null));
    }
  }, [selectedStep, registerData, faceMatchAndOCR]);

  let count = 1;

  useEffect(() => {
    const typeSteps = steps.map(step => step.systemStep.step);
    const clientId = registerData?.id;

    if (clientId) {
      if (typeSteps.includes(SystemStepCategory.DOC_FRAUD_ANALYSIS)) {
        clientDataService.getFaceMatchAndOCRAdmin(clientId).then(res => setFaceMatchAndOCR(res));
      }

      if (typeSteps.includes(SystemStepCategory.SCORE_ANALYSIS)) {
        clientDataService.getClientMultiCredit(clientId).then(res => setMultiCredit(res));
      }

      if (typeSteps.includes(SystemStepCategory.SCORE_ANALYSIS_DTI)) {
        clientDataService.getClientDTI(clientId).then(res => setDti(res.dti));
      }
    }
  }, [registerData, steps]);

  useEffect(() => {
    const systemSteps = steps.map(step => step.systemStep.step);
    const findPersonalStep = systemSteps.findIndex(
      step => step === SystemStepCategory.PERSONAL_DATA || step === SystemStepCategory.PERSONAL_DATA_MULTTIPLO
    );

    if (findPersonalStep >= 0) {
      setSelectedStep(systemSteps[findPersonalStep]);
    }
  }, [steps]);

  useEffect(() => {
    if (!isPhysicalPerson) {
      setSelectedStep(SystemStepCategory.GENERAL_DATA);
    }
  }, []);

  return (
    <>
      <StepsContainer>
        {steps.map((elem, index) => (
          <StepDisplay
            key={index}
            step={elem.systemStep.step}
            selectedStep={selectedStep}
            setSelectedStep={setSelectedStep}
            stepName={getStepName(elem)}
          />
        ))}
      </StepsContainer>
      <CampDisplay camps={camps} handleEditSelfie={handleEditSelfie} />
      <CustomComponentModal open={showComponentModal} children={customComponent} setOpen={setShowComponentModal} />
    </>
  );
};
